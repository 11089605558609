export const stageTranslator = (stage) => {
  if (stage === 5 || stage === 8) {
    return 'Grand finals';
  } else if (stage === 6) {
    return 'All Stars';
  } else if (stage === 7) {
    return 'Playoffs';
  } else {
    return `Stage ${stage}`;
  }
};

export const stageUrlTranslator = (stage) => {
  if (stage === 5 || stage === 8) {
    return '/grand-finals';
  } else if (stage === 6) {
    return '/all-stars';
  } else if (stage === 7) {
    return '/playoffs';
  } else {
    return `/stage-${stage}`;
  }
};

export const weekUrlTranslator = (week) => {
  if (week === 6) {
    return '/stage-playoffs';
  } else {
    return `/week-${week}`;
  }
};

export const weekTranslator = (week) => {
  if (week === 6) {
    return 'Stage playoffs';
  } else {
    return `Week ${week}`;
  }
};
