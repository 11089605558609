import React from 'react';

const ClapperboardIcon = ({ className }) => {
  return (
    <svg
      className={className}
      viewBox="0 0 304 258"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M171.857 13.736l-67.258 7.31 34.698 37.47 65.796-7.151-33.236-37.63zm88.892 31.58l41.925-4.556-2.809-25.848c-.896-8.236-8.298-14.186-16.533-13.291l-57.281 6.225 34.698 37.47zM14.912 30.792l35.493-3.857 33.236 37.63L4.43 73.172 1.62 47.325c-.894-8.236 5.056-15.638 13.292-16.533z"
        fill="#FF8801"
      />
      <path
        d="M205.441 94l-29.516 34.9h56L260.016 94H304v149c0 8.284-6.716 15-15 15H19c-8.284 0-15-6.716-15-15V94h79.441l-29.516 34.9h56L138.016 94h67.425z"
        fill="#393939"
      />
    </svg>
  );
};

export default ClapperboardIcon;
