import React, { useContext, useMemo } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { CSSTransition } from 'react-transition-group';

import { DISPLAY_WEEK_DROPDOWN, SET_WEEK } from './FilterReducer';
import FilterContext from './FilterContext';

import {
  StyledFilterWrapper,
  StyledButton,
  StyledDropdown,
  StyledFilterButton,
} from './DropdownElements';
import DownIcon from '../icons/DownIcon';

import { weekTranslator } from '../../lib/translators';

const WeekDropdown = () => {
  const { state, dispatch } = useContext(FilterContext);
  const data = useStaticQuery(graphql`
    query WeeksQuery {
      allMyNodeTypeWeek {
        nodes {
          id
          season
          stage
          week
        }
      }
    }
  `);

  return useMemo(() => {
    return (
      <StyledFilterWrapper>
        <StyledButton
          disabled={!state.stage}
          type="button"
          data-toggle="dropdown"
          id="week-filter-toggle"
          aria-haspopup="true"
          aria-expanded={state.displayWeekOptions}
          onClick={() => {
            dispatch({
              type: DISPLAY_WEEK_DROPDOWN,
              payload: !state.displayWeekOptions,
            });
          }}
        >
          {state.week
            ? `${state.week < 6 ? `Week ${state.week}` : 'Stage playoffs'}`
            : 'All weeks'}
          <DownIcon />
        </StyledButton>
        <CSSTransition
          in={state.displayWeekOptions}
          mountOnEnter
          timeout={{
            enter: 100,
            exit: 100,
          }}
          unmountOnExit
          classNames="dropdown"
        >
          <StyledDropdown
            id="week-filter-dropdown"
            aria-labelledby="week-filter-toggle"
          >
            <ul>
              <li key={`season-${state.season}-stage-${state.stage}-week-any`}>
                <StyledFilterButton
                  active={state.week === null ? true : false}
                  onClick={() => {
                    dispatch({ type: SET_WEEK, payload: null });
                  }}
                >
                  All weeks
                </StyledFilterButton>
              </li>

              {data &&
                data.allMyNodeTypeWeek.nodes
                  .filter((week) => week.season === state.season)
                  .filter((week) => week.stage === state.stage)
                  .map((week) => (
                    <li key={week.id}>
                      <StyledFilterButton
                        active={state.week === week.week ? true : false}
                        onClick={() => {
                          dispatch({
                            type: SET_WEEK,
                            payload: parseInt(week.week),
                          });
                        }}
                      >
                        {weekTranslator(week.week)}
                      </StyledFilterButton>
                    </li>
                  ))}
            </ul>
          </StyledDropdown>
        </CSSTransition>
      </StyledFilterWrapper>
    );
  }, [
    data,
    dispatch,
    state.displayWeekOptions,
    state.season,
    state.stage,
    state.week,
  ]);
};

export default WeekDropdown;
