import styled from 'styled-components';

import media from '../../styles/media';

export const StyledFilterWrapper = styled.div`
  position: relative;
  width: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.03);

  ${media.tablet`
    width: 25%;
    border-left: 1px solid rgba(0, 0, 0, 0.03);
    border-bottom: none;
  `}

  &:last-of-type {
    border-right: none;
    border-bottom: none;
  }
`;

export const StyledButton = styled.button`
  border: none;
  background-color: white;
  padding: 15px 20px;
  margin: 0;
  font-size: 18px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${(props) => props.theme.black};
  transition: color 0.25s ease-in-out;
  height: 100%;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  transition: background-color 0.15s ease-in-out;
  width: 100%;

  ${media.desktop`
    padding: 10px 20px;
  `}

  &:hover,
  &:focus,
  &:active {
    background-color: rgba(0, 0, 0, 0.025);
    color: ${(props) => props.theme.black};
    outline: none;
  }

  > svg {
    transition: transform 0.15s ease-in-out;
    height: 25px;
  }

  &[aria-expanded='true'] {
    svg {
      transform: rotate(180deg);
    }
  }

  &:disabled {
    opacity: 0.25;
    cursor: not-allowed;
  }
`;

export const StyledDropdown = styled.div`
  background-color: ${(props) => props.theme.black};
  padding: 10px 0;
  color: white;
  position: relative;
  min-width: 100%;
  z-index: 100;
  box-shadow: ${(props) => props.theme.bs};

  &.dropdown-enter {
    opacity: 0;
  }

  &.dropdown-enter-active {
    opacity: 1;
    transition: all 150ms ease-in-out;
  }

  &.dropdown-exit {
    opacity: 1;
  }

  &.dropdown-exit-active {
    opacity: 0;
    transition: all 150ms ease-in-out;
  }

  ${media.tablet`
    border-radius: 4px;
    position: absolute;

    &.dropdown-enter {
      opacity: 0;
      transform: translateY(0px);
    }

    &.dropdown-enter-active {
      opacity: 1;
      transition: all 150ms ease-in-out;
      transform: translateY(15px);
    }

    &.dropdown-enter-done {
      transform: translateY(15px);
    }

    &.dropdown-exit {
      opacity: 1;
      transform: translateY(15px);
    }

    &.dropdown-exit-active {
      opacity: 0;
      transition: all 150ms ease-in-out;
      transform: translateY(15px);
    }
  `}

  > ul {
    padding: 0;
    margin: 0;
    list-style-type: none;

    > li {
      padding: 0;
      font-family: sans-serif;
    }
  }
`;

export const StyledFilterButton = styled.button`
  cursor: pointer;
  background-color: transparent;
  border: none;
  padding: 10px 20px;
  margin: 0;
  font-size: 16px;
  display: block;
  width: 100%;
  text-align: left;
  font-family: 'Roboto', sans-serif;
  color: ${(props) => (props.active ? props.theme.pizazz : '#ffffff')};

  &:hover,
  &:focus,
  &:active {
    color: ${(props) => props.theme.pizazz};
    outline: none;
  }
`;
