import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import useWindowWidth from '../hooks/useWindowWidth';

import Filter from './Filter/Filter';
import Logo from './Logo';
import SlidersIcon from './icons/SlidersIcon';

import media from '../styles/media';

const StyledHeader = styled.header`
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  background-color: white;
  box-shadow: ${(props) => props.theme.bs};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 4px;

  ${media.tablet`
    margin: 30px auto;
  `};

  ${media.desktop`
    justify-content: space-between;
    flex-direction: row;
  `};
`;

const StyledMenuButton = styled.button`
  background: transparent;
  outline: border;
  padding: 10px;
  margin: 0 10px 0 0;
  border: none;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.1s ease-in-out;

  &:hover,
  &:active,
  &:focus {
    background-color: rgba(0, 0, 0, 0.025);
  }

  > span {
    color: ${(props) => props.theme.black};
    font-size: 16px;
    padding-right: 10px;
    text-transform: uppercase;
  }

  > svg {
    height: 20px;
    width: 20px;
  }
`;

const StyledFlex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  ${media.tablet`
    width: 200px;
    max-width: 200px;
    justify-content: center;
  `}
`;

const Header = ({ season, stage, week, path }) => {
  const width = useWindowWidth();
  const [showMenu, setShowMenu] = useState(false);

  useEffect(() => {
    setShowMenu(width > 768 ? true : false);
  }, [width]);

  useEffect(() => {
    setShowMenu(width < 768 ? false : true);
  }, [path, width]);

  return (
    <StyledHeader>
      <StyledFlex>
        <Logo />
        {width < 768 && (
          <StyledMenuButton
            aria-haspopup="true"
            aria-expanded={showMenu}
            onClick={() => setShowMenu(!showMenu)}
          >
            <span>Filter</span> <SlidersIcon />
          </StyledMenuButton>
        )}
      </StyledFlex>
      {showMenu && (
        <Filter
          width={width}
          showMenu={showMenu}
          setShowMenu={setShowMenu}
          season={season}
          stage={stage}
          week={week}
          path={path}
        />
      )}
    </StyledHeader>
  );
};

export default Header;
