import { useState, useEffect } from 'react';
import debounce from 'lodash.debounce';

function useWindowSize() {
  const isClient = typeof window === 'object';
  const [width, setWidth] = useState(isClient ? window.innerWidth : undefined);

  useEffect(() => {
    if (!isClient) {
      return false;
    }

    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener('resize', debounce(handleResize, 100));

    return () => window.removeEventListener('resize', handleResize);
  }, [isClient]); // Empty array ensures that effect is only run on mount and unmount

  return width;
}

export default useWindowSize;
